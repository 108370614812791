import React, { useEffect, useState } from "react";
import { getUserData } from "../utils/localStorage";
import { Card, Col, Container, Nav, Navbar, Row } from "react-bootstrap";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import {
  getBrandPriceCode,
  postBrandPriceListConfigData,
} from "../services/getPriceCode";
import { errorMessage, successMessage } from "../utils/alertMessage";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { signIn, storePickupRequest } from "../services/SignIn";

const PickupRequest = () => {
  const navigate = useNavigate();

  const userData = getUserData();
  let brandId = userData.brandProfileData.id;
  const [brandPackageList, setBrandPackageList] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [submitButtonPressed, setSubmitButtonPressed] = useState(false);

  const [input, setInput] = useState({
    appServiceList: "",
    serviceUnit: "",
    priceId: "",
  });
  const [errors, setErrors] = useState({
    appServiceList: "",
    serviceUnit: "",
    priceId: "",
  });

  const fetchGetBrandPriceCode = () => {
    getBrandPriceCode(brandId)
      .then((response) => {
        if (!response.data.error) {
          const res = response.data;
          // const priceList = res.prices;
          const filteredData = res.packages.filter(
            (item) => item.status === true
          );
          const packageList = filteredData.map((item) => ({
            label: item.name,
            value: item.price.id,
            appService: item.appService, // Assuming this field exists in your API response
            serviceUnit: item.serviceUnit, // Assuming this field exists in your API response
          }));
          setBrandPackageList(packageList);
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };

  useEffect(() => {
    fetchGetBrandPriceCode();
  }, []);

  const handlePackageChange = (e) => {
    const selectedPackage = brandPackageList.find(
      (pkg) => pkg.value === e.target.value
    );
    setSelectedPackage(selectedPackage);
    setInput({
      ...input,
      priceId: e.target.value,
      appServiceList: selectedPackage?.appService ?? "",
      serviceUnit: selectedPackage?.serviceUnit ?? "",
    });
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = { ...errors };

    if (input.priceId === "") {
      newErrors.priceId = "Please select any Package List";
      isValid = false;
    } else {
      newErrors.priceId = "";
    }
    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let data = {
      apiKey: "97OIT",
      customerMobile: "8750001881",
      remarks: "test",
      pickupOn: "2024-05-15",
      pickupTimeSlot: "06 to 08",
      storeId: "D1OMDBNSA",
      riderId: "97OITTEK",
    };
    signIn({
      username: "97OITANV",
      password: "1234",
      source: "web",
      version: "2.1",
    })
      .then((response) => {
        if (response && response.data && !response.data.error) {
          const token = response.data.accessToken;
          storePickupRequest(token, data)
            .then((response) => {
              if (!response.data.error) {
                if (response.data.success === false) {
                  errorMessage(response.data.message);
                } else {
                  successMessage(response.data.message);
                }
              }  
            })
            .catch((err) => {
              errorMessage();
            });
        }
      })
      .catch((err) => {
        errorMessage();
      });
  };

  return (
    <div>
      <Navbar
        expand="lg"
        // className=" backgroundColor-white"
        style={{
          backgroundColor: "#FBFBFB",
          //   backgroundImage: "linear-gradient(to bottom, #1752aa, #2b70d7)",
        }}
      >
        <Container>
          <Button
            style={{
              background: "none",
              color: "#1752aa",
              border: "none",
              left: -20,
            }}
            onClick={() => navigate(-1)}
          >
            <i className="pi pi-arrow-left" style={{ fontSize: "2rem" }}></i>
          </Button>
          <Navbar.Brand href="/">
            <img
              style={{ width: "120px" }}
              src="/Img/DhobiCart.png"
              alt="logo"
            />
          </Navbar.Brand>

          <Navbar.Toggle />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            <Nav className="me-auto-new">
              <Nav.Link className="nav-link-design" href="/logout">
                <i
                  className="pi pi-power-off"
                  style={{
                    fontSize: "2rem",
                    color: "red",
                    cursor: "pointer",
                  }}
                ></i>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container fluid>
        <Row>
          <Col style={{ margin: 10 }}>
            <div style={{ textAlign: "center" }}>
              <h3>Pickup Request</h3>
            </div>
            <main id="main" className="main">
              <section className="section">
                <div className="row">
                  <div className="col-lg-12">
                    <Card
                      style={{
                        backgroundColor: "#1752AA",
                        color: "white",
                        marginTop: 20,
                      }}
                    >
                      <Card.Body>
                        <form onSubmit={handleSubmit}>
                          <div className="col-md-12 text-center">
                            <button
                              type="submit"
                              className="move-btn-a move-btn-mu"
                              disabled={submitButtonPressed}
                            >
                              Generate
                            </button>
                          </div>
                        </form>
                      </Card.Body>
                    </Card>
                  </div>
                </div>
              </section>
            </main>
          </Col>
          <Col style={{ textAlign: "center", marginTop: 50 }}>
            <img src="/Img/5.png" alt="logo" />
          </Col>
        </Row>
      </Container>
      <footer style={{ backgroundColor: "#FBFBFB" }}>
        <Container fluid>
          <Row>
            <Col>
              <Nav className="flex social-media-div" style={{ marginLeft: 30 }}>
                <Link
                  to="https://www.youtube.com/channel/UCKdUWjGim18SoHC2jLbFsiw"
                  target="_blank"
                >
                  <i
                    className="pi pi-youtube"
                    style={{
                      fontSize: "2rem",
                      color: "red",
                      cursor: "pointer",
                    }}
                  ></i>
                </Link>
                <Link
                  to="https://www.instagram.com/dhobicart/?hl=en"
                  target="_blank"
                >
                  <i
                    className="pi pi-instagram"
                    style={{
                      fontSize: "2rem",
                      color: "#d62976",
                      cursor: "pointer",
                    }}
                  ></i>
                </Link>
                <Link
                  to="https://www.linkedin.com/company/71974228/admin/dashboard/"
                  target="_blank"
                >
                  <i
                    className="pi pi-linkedin"
                    style={{
                      fontSize: "2rem",
                      color: "#0072b1",
                      cursor: "pointer",
                    }}
                  ></i>
                </Link>
                <Link
                  to="https://www.facebook.com/oneindiatechno"
                  target="_blank"
                >
                  <i
                    className="pi pi-facebook"
                    style={{
                      fontSize: "2rem",
                      color: "#1877F2",
                      cursor: "pointer",
                    }}
                  ></i>
                </Link>
                <Link
                  to="https://play.google.com/store/apps/details?id=com.dhobicartV2"
                  target="_blank"
                >
                  <i
                    className="pi pi-android"
                    style={{
                      fontSize: "2rem",
                      color: "#a4c639",
                      cursor: "pointer",
                    }}
                  ></i>
                </Link>
                <Link to="tel:+91 9319518337" target="_blank">
                  <i
                    className="pi pi-phone"
                    style={{
                      fontSize: "2rem",
                      color: "#1877F2",
                      cursor: "pointer",
                    }}
                  ></i>
                </Link>
              </Nav>
            </Col>
            {/*<Col>
              <Link
                to="https://www.oneindiatech.com/?page_id=75"
                target="_blank"
                style={{ textDecoration: "none", fontSize: 20 }}
              >
                Contact Us!
              </Link>
              <p>Phone : +91 9319518337</p>
            </Col>*/}
          </Row>
          <Row>
            <Col>
              <div className="text-center p-3" style={{ fontSize: 16 }}>
                &copy; {new Date().getFullYear()} Copyright:{" "}
                <a className="text-dark" href="https://oneindiatech.com/">
                  One India Technologies Pvt. Ltd.
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </div>
  );
};

export default PickupRequest;
