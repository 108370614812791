import React, { useEffect, useState } from "react";
import { Card, Col, Container, Nav, Navbar, Row } from "react-bootstrap";
import { Button } from "primereact/button";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getUserData } from "../../utils/localStorage";
import {
  getBrandSingleOffer,
  postBrandPriceList,
  updateBrandOffer,
} from "../../services/getBrandOffers";
import { InputText } from "primereact/inputtext";
import { errorMessage, successMessage } from "../../utils/alertMessage";
import { getBrandPriceCode } from "../../services/getPriceCode";
import "jspdf-autotable";
import jsPDF from "jspdf";

const OfferGarmentSelection = () => {
  const navigate = useNavigate();
  const [garmentList, setGarmentList] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredGarments, setFilteredGarments] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [singleOfferData, setSingleOfferData] = useState({});
  const [formData, setFormData] = useState({});
  const [submitButtonPressed, setSubmitButtonPressed] = useState(false);
  const [loading, setLoading] = useState(true);
  const [offerMapData, setOfferMapData] = useState({});

  const userData = getUserData();
  const brandId = userData?.brandProfileData?.id;

  const { state } = useLocation();
  const { priceId, offerId, offerName } = state;

  const [brandPriceList, setBrandPriceList] = useState([]);
 
  useEffect(() => {
    getBrandPriceCode(brandId)
      .then((response) => {
        if (!response.data.error) {
          const res = response.data;
          const filteredData = res.prices.filter(
            (price) => price.status === true && price.id === priceId
          );
          setBrandPriceList(filteredData);
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });

    let offersMap = {};
    getBrandSingleOffer(brandId, offerId)
      .then((response) => {
        if (!response.data.error) {
          const Res = response.data;
          setSingleOfferData(Res);

          offersMap = Res?.itemList?.reduce((map, item) => {
            // map[item] = item;
            map[item] = true;
            return map;
          }, {});

          setOfferMapData(offersMap);
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });

    const priceListData = {
      priceIds: [priceId],
      priceType: "PRICE",
    };

    postBrandPriceList(brandId, priceListData)
      .then((response) => {
        if (!response.data.error) {
          setLoading(false);
          // Transforming the data to match the expected structure
          const transformedData = response.data.reduce((acc, item) => {
            const garmentName = item.garmentCategory.garment.name;
            const categoryName = item.garmentCategory.category.name;
            const serviceName = item.appService.name;

            if (!acc[garmentName]) acc[garmentName] = {};
            if (!acc[garmentName][categoryName])
              acc[garmentName][categoryName] = {};

            acc[garmentName][categoryName][serviceName] = {
              // status: item.status,
              id: item.id,
              status:
                offersMap[item.id] !== null && offersMap[item.id] !== undefined
                  ? true
                  : false,
            };

            return acc;
          }, {});

          setGarmentList(transformedData);
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  }, [brandId, priceId]);

  useEffect(() => {
    if (searchQuery) {
      setFilteredGarments(
        Object.keys(garmentList).filter((garment) =>
          garment.toLowerCase().includes(searchQuery.toLowerCase())
        )
      );
    } else {
      setFilteredGarments(Object.keys(garmentList));
    }
  }, [searchQuery, garmentList]);

  const allServices = Array.from(
    new Set(
      Object.keys(garmentList).reduce((services, garment) => {
        Object.keys(garmentList[garment]).forEach((category) => {
          Object.keys(garmentList[garment][category]).forEach((service) => {
            services.push(service);
          });
        });
        return services;
      }, [])
    )
  );

  const handleInputChange = (event, garmentName, categoryName, serviceName) => {
    const value = event.target.checked;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [`${garmentName}-${categoryName}-${serviceName}`]: value,
    }));
  };

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSelectAllChange = (event) => {
    const isChecked = event.target.checked;
    setSelectAllChecked(isChecked);
    const updatedFormData = {};
    Object.keys(garmentList).forEach((garment) => {
      Object.keys(garmentList[garment]).forEach((category) => {
        Object.keys(garmentList[garment][category]).forEach((service) => {
          updatedFormData[`${garment}-${category}-${service}`] = isChecked;
        });
      });
    });
    setFormData(updatedFormData);
  };

  const downloadPDF = (data, allServices, type) => {
    const doc = new jsPDF("landscape");
    const logoImg = new Image();

    doc.setFontSize(20);
    doc.text("Offer Garment Management", 110, 25);

    const tableData = data
      .map((garment) => {
        return Object.keys(garmentList[garment])
          .map((category, index) => {
            const row = [garment, category];
            let includeRow = false; // Flag to check if the row should be included
            allServices.forEach((service) => {
              const priceData = garmentList[garment][category][service];
              if (priceData && priceData.status === true) {
                includeRow = true; // Mark the row to be included
                row.push(
                  formData[`${garment}-${category}-${service}`] !== undefined
                    ? formData[`${garment}-${category}-${service}`]
                    : "True"
                );
              } else {
                row.push("");
              }
            });
            return includeRow ? row : null; // Only return the row if it should be included
          })
          .filter((row) => row !== null); // Filter out the null rows
      })
      .flat();

    const tableHeaders = ["Garment Name", "Category", ...allServices];

    doc.autoTable({
      head: [tableHeaders],
      body: tableData,
      startY: 30,
      theme: "grid",
      styles: {
        fontSize: 10,
      },
    });
    if (type === "print") {
      // Create blob URL
      const blob = doc.output("blob");
      const blobURL = URL.createObjectURL(blob);

      // Open blob URL in a new window
      const printWindow = window.open(blobURL);
      if (printWindow) {
        printWindow.onload = () => {
          printWindow.print();
        };
      }
    } else {
      //  doc.save("Offer-garment-management.pdf");
      doc.save("Offer-garment-" + brandPriceList[0].priceCode + ".pdf");
    }
  };

  const handleSubmit = () => {
    setSubmitButtonPressed(true);

    // Initialize submittedData with already present data
    let submittedData = singleOfferData.itemList
      ? [...singleOfferData.itemList]
      : [];

    Object.keys(garmentList).forEach((garment) => {
      Object.keys(garmentList[garment]).forEach((category) => {
        Object.keys(garmentList[garment][category]).forEach((service) => {
          const serviceData = garmentList[garment][category][service];
          const inputValue = formData[`${garment}-${category}-${service}`];
          const originalStatus = serviceData.status;

          if (inputValue !== undefined && !inputValue && serviceData.status) {
            submittedData = submittedData.filter((id) => id !== serviceData.id);
          } else if (serviceData.status) {
            // If serviceData.status is true, add serviceData.id if not already in submittedData
            if (!submittedData.includes(serviceData.id)) {
              submittedData.push(serviceData.id);
            }
          }

          // If inputValue is true, add serviceData.id if not already in submittedData
          if (inputValue && !submittedData.includes(serviceData.id)) {
            submittedData.push(serviceData.id);
          }
        });
      });
    });

    const updatedData = {
      brandOfferDTO: singleOfferData.brandOfferDTO,
      itemList: submittedData, // selected checkbox ids
    };

    updateBrandOffer(brandId, updatedData)
      .then((response) => {
        if (!response.data.error) {
          const Res = response.data;
          if (Res) {
            // Reset state variables
            if (response.data.success === false) {
              errorMessage(response.data.message);
              setSubmitButtonPressed(false);
            } else {
              successMessage(response.data.message);
              setSubmitButtonPressed(false);
              navigate("/offer-management");
            }
          }
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };

   const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10); // Default items per page
  
    const garments =
      filteredGarments?.sort();
  
    // Calculate total pages
    const totalPages = Math.ceil(garments.length / itemsPerPage);
  
    // Get current garments to display
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentGarments = garments.slice(indexOfFirstItem, indexOfLastItem);
  
  
  const renderGarments = () => {
    return currentGarments.sort().map((garment) =>
      Object.keys(garmentList[garment]).map((category, index) => (
        <tr key={`${garment}-${category}`}>
          <td>{index === 0 ? garment : null}</td>
          <td>{category}</td>
          {allServices.map((service) => {
            const priceData =
              garmentList[garment] &&
              garmentList[garment][category] &&
              garmentList[garment][category][service];

            // Check if priceData is present and if amount is present
            if (priceData && priceData.status !== undefined) {
              return (
                <td key={`${garment}-${category}-${service}`}>
                  <input
                    type="checkbox"
                    style={{ width: 25, height: 25 }}
                    id={`${garment}-${category}-${service}`}
                    name={`${garment}-${category}-${service}`}
                    checked={
                      formData[`${garment}-${category}-${service}`] !==
                      undefined
                        ? formData[`${garment}-${category}-${service}`]
                        : garmentList[garment][category][service].status
                    }
                    onChange={(e) =>
                      handleInputChange(e, garment, category, service)
                    }
                  />
                </td>
              );
            } else {
              // If amount is not present, render an empty cell
              return <td key={`${garment}-${category}-${service}`}></td>;
            }
          })}
        </tr>
      ))
    );
  };

   const getPageNumbers = () => {
     const maxPageNumbersToShow = 5;
     const pageNumbers = [];

     let startPage = Math.max(1, currentPage - 2);
     let endPage = Math.min(totalPages, startPage + maxPageNumbersToShow - 1);

     if (endPage - startPage + 1 < maxPageNumbersToShow) {
       startPage = Math.max(1, endPage - maxPageNumbersToShow + 1);
     }

     for (let i = startPage; i <= endPage; i++) {
       pageNumbers.push(i);
     }

     return pageNumbers;
   };

   // Handle page change
   const handlePageChange = (newPage) => {
     if (newPage >= 1 && newPage <= totalPages) {
       setCurrentPage(newPage);
     }
   };

   // Handle items per page change
   const handleItemsPerPageChange = (event) => {
     setItemsPerPage(Number(event.target.value));
     setCurrentPage(1); // Reset to first page when changing items per page
   };

  return (
    <div style={{ position: "relative", minHeight: "100vh" }}>
      <Navbar
        expand="lg"
        // className=" backgroundColor-white"
        style={{
          backgroundColor: "#FBFBFB",
          //   backgroundImage: "linear-gradient(to bottom, #1752aa, #2b70d7)",
        }}
      >
        <Container>
          {/*<Button
            style={{
              background: "none",
              color: "#1752aa",
              border: "none",
              left: -20,
            }}
            onClick={() => navigate(-1)}
          >
            <i className="pi pi-arrow-left" style={{ fontSize: "2rem" }}></i>
          </Button>*/}
          <Navbar.Brand href="/">
            <img
              style={{ width: "120px" }}
              src="/Img/DhobiCart.png"
              alt="logo"
            />
          </Navbar.Brand>

          <Navbar.Toggle />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            <Nav className="me-auto-new">
              <Nav.Link className="nav-link-home" href="/">
                Home
              </Nav.Link>
              <Nav.Link className="nav-link-design" href="/logout">
                <i
                  className="pi pi-power-off"
                  style={{
                    fontSize: "2rem",
                    color: "red",
                    cursor: "pointer",
                  }}
                ></i>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {loading ? (
        <Row>
          <Col style={{ margin: 10 }}>
            <div style={{ textAlign: "center" }}>
              <h1>Loading...</h1>
            </div>
          </Col>
        </Row>
      ) : Object.keys(garmentList).length > 0 ? (
        <Container fluid>
          <div style={{ textAlign: "center" }}>
            <h3>Offer Garment Management</h3>
          </div>
          <div className="row mb-3">
            <div className="col-md-6">
              <div
                style={{
                  // backgroundColor: "#1752AA",
                  // color: "white",
                  marginTop: 20,
                }}
              >
                <div
                  style={{
                    textDecoration: "none",
                    display: "flex",
                    // flex: 1,
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    // width: "280px",
                    // height: "280px",
                    backgroundColor: "#fff",
                    paddingTop: 25,
                    borderRadius: 20,
                    cursor: "pointer",
                    boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                    transition: "0.3s",
                    margin: 5,
                    marginTop: 15,
                    marginBottom: 15,
                  }}
                >
                  <div className="row mb-3">
                    <div className="col-md-12">
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <img
                          style={{ width: "86px" }}
                          src="/Img/rupee_1.png"
                          alt="logo"
                        />
                      </div>
                      <div style={{ textAlign: "center", height: 70 }}>
                        <h5>Tariff List</h5>
                        <p className="service-list display-contents">
                          {brandPriceList[0].priceCode}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div
                style={{
                  // backgroundColor: "#1752AA",
                  // color: "white",
                  marginTop: 20,
                }}
              >
                <div
                  style={{
                    textDecoration: "none",
                    display: "flex",
                    // flex: 1,
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    // width: "280px",
                    // height: "280px",
                    backgroundColor: "#fff",
                    paddingTop: 25,
                    borderRadius: 20,
                    cursor: "pointer",
                    boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                    transition: "0.3s",
                    margin: 5,
                    marginTop: 15,
                    marginBottom: 15,
                  }}
                >
                  <div className="row mb-3">
                    <div className="col-md-12">
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <img
                          style={{ width: "86px", color: "#fff" }}
                          src="/Img/special-tag.png"
                          alt="logo"
                        />
                      </div>
                      <div style={{ textAlign: "center", height: 70 }}>
                        <h5>Offers List</h5>
                        <p className="service-list display-contents">
                          {offerName}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              margin: 20,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <InputText
              value={searchQuery}
              onChange={handleSearchInputChange}
              placeholder="Search Garment"
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div style={{ alignSelf: "center" }}>
                <h5>Select All</h5>
              </div>
              <div style={{ marginLeft: 20, marginRight: 20 }}>
                <input
                  type="checkbox"
                  style={{ width: 30, height: 30 }}
                  checked={selectAllChecked}
                  onChange={handleSelectAllChange}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 20,
                  // marginTop: 10,
                }}
              >
                <i
                  onClick={() =>
                    downloadPDF(
                      filteredGarments.sort(),
                      allServices,
                      "download"
                    )
                  }
                  className="pi pi-download"
                  style={{
                    fontSize: "2rem",
                    color: "#1e439b",
                    cursor: "pointer",
                  }}
                ></i>
                <i
                  onClick={() =>
                    downloadPDF(filteredGarments.sort(), allServices, "print")
                  }
                  className="pi pi-print"
                  style={{
                    fontSize: "2rem",
                    color: "#ff0101",
                    cursor: "pointer",
                  }}
                ></i>
              </div>
            </div>
          </div>
          <Row style={{ marginBottom: 60 }}>
            <Col style={{ margin: 10 }}>
              <main id="main" className="main">
                <section className="section">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="tableFixHead">
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th>Garment Name</th>
                              <th>Category</th>
                              {allServices.map((service) => (
                                <th key={service}>{service}</th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>{renderGarments()}</tbody>
                        </table>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "20px",
                          alignItems: "center",
                        }}
                      >
                        {/* Previous Button */}
                        <Button
                          label="Prev"
                          disabled={currentPage === 1}
                          onClick={() => handlePageChange(currentPage - 1)}
                        />

                        {/* Page Numbers */}
                        {getPageNumbers().map((number) => (
                          <span
                            key={number}
                            onClick={() => handlePageChange(number)}
                            style={{
                              cursor: "pointer",
                              margin: "0 15px",
                              fontWeight:
                                currentPage === number ? "bold" : "normal",
                              // textDecoration:
                              //   currentPage === number ? "underline" : "none",
                              color: currentPage === number ? "blue" : "black",
                              fontSize:
                                currentPage === number ? "20px" : "15px",
                            }}
                          >
                            {number}
                          </span>
                        ))}

                        {/* Show "..." if more pages exist */}
                        {totalPages > 5 && currentPage < totalPages - 2 && (
                          <span
                            style={{
                              margin: "0 15px",
                            }}
                          >
                            ...
                          </span>
                        )}

                        {/* Next Button */}
                        <Button
                          label="Next"
                          disabled={currentPage === totalPages}
                          onClick={() => handlePageChange(currentPage + 1)}
                        />

                        {/* Items Per Page Dropdown */}
                        <div style={{ marginLeft: "15px" }}>
                          <select
                            value={itemsPerPage}
                            onChange={handleItemsPerPageChange}
                          >
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </div>
                      </div>
                      {Object.keys(garmentList).length > 0 && (
                      <div className="flex justify-content-center align-items-center mt-4">
                          <Button
                            label="Submit"
                            onClick={handleSubmit}
                            disabled={submitButtonPressed}
                            style={{ position: "relative" }}
                            className="font-bold px-5 py-3 p-button-raised p-button-rounded white-space-nowrap"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </section>
              </main>
            </Col>
          </Row>
        </Container>
      ) : (
        <Row>
          <Col style={{ margin: 10 }}>
            <div style={{ textAlign: "center" }}>
              <h3 style={{ color: "red" }}>
                Please Add Price in Tariff List Garments
              </h3>
            </div>
          </Col>
        </Row>
      )}
      {/*Object.keys(garmentList).length > 0 && (
        <div
          style={{
            position: "fixed",
            bottom: 20,
            width: "100%",
            textAlign: "center",
          }}
        >
          <Button
            label="Submit"
            onClick={handleSubmit}
            disabled={submitButtonPressed}
            className="p-button-primary"
            style={{ position: "relative" }}
          />
        </div>
      )*/}
      <footer>
        <Container fluid>
          <Row>
            <Col>
              <Nav className="flex social-media-div" style={{ marginLeft: 30 }}>
                <Link
                  to="https://www.youtube.com/channel/UCKdUWjGim18SoHC2jLbFsiw"
                  target="_blank"
                >
                  <i
                    className="pi pi-youtube"
                    style={{
                      fontSize: "2rem",
                      color: "red",
                      cursor: "pointer",
                    }}
                  ></i>
                </Link>
                <Link
                  to="https://www.instagram.com/dhobicart/?hl=en"
                  target="_blank"
                >
                  <i
                    className="pi pi-instagram"
                    style={{
                      fontSize: "2rem",
                      color: "#d62976",
                      cursor: "pointer",
                    }}
                  ></i>
                </Link>
                <Link
                  to="https://www.linkedin.com/company/71974228/admin/dashboard/"
                  target="_blank"
                >
                  <i
                    className="pi pi-linkedin"
                    style={{
                      fontSize: "2rem",
                      color: "#0072b1",
                      cursor: "pointer",
                    }}
                  ></i>
                </Link>
                <Link
                  to="https://www.facebook.com/oneindiatechno"
                  target="_blank"
                >
                  <i
                    className="pi pi-facebook"
                    style={{
                      fontSize: "2rem",
                      color: "#1877F2",
                      cursor: "pointer",
                    }}
                  ></i>
                </Link>
                <Link
                  to="https://play.google.com/store/apps/details?id=com.dhobicartV2"
                  target="_blank"
                >
                  <i
                    className="pi pi-android"
                    style={{
                      fontSize: "2rem",
                      color: "#a4c639",
                      cursor: "pointer",
                    }}
                  ></i>
                </Link>
                <Link to="tel:+91 9319518337" target="_blank">
                  <i
                    className="pi pi-phone"
                    style={{
                      fontSize: "2rem",
                      color: "#1877F2",
                      cursor: "pointer",
                    }}
                  ></i>
                </Link>
              </Nav>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-center p-3" style={{ fontSize: 16 }}>
                &copy; {new Date().getFullYear()} Copyright:{" "}
                <a className="text-dark" href="https://oneindiatech.com/">
                  One India Technologies Pvt. Ltd.
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </div>
  );
};

export default OfferGarmentSelection;
