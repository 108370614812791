import React, { useEffect, useState } from "react";
import { Card, Col, Container, Nav, Navbar, Row } from "react-bootstrap";
import { Button } from "primereact/button";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getUserData } from "../../utils/localStorage";
import {
  getBrandSingleOffer,
  postBrandPriceList,
  updateBrandOffer,
} from "../../services/getBrandOffers";
import { InputText } from "primereact/inputtext";
import { errorMessage, successMessage } from "../../utils/alertMessage";
import { getBrandPriceCode } from "../../services/getPriceCode";
import "jspdf-autotable";
import jsPDF from "jspdf";
import { getSinglePackageOffer, updatePackageOffer } from "../../services/getPackageOffer";

const PackageOffersGarmentSelection = () => {
  const navigate = useNavigate();
  const [garmentList, setGarmentList] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredGarments, setFilteredGarments] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [singleOfferData, setSingleOfferData] = useState({});
  const [formData, setFormData] = useState({});
  const [submitButtonPressed, setSubmitButtonPressed] = useState(false);
  const [loading, setLoading] = useState(true);
  const [offerMapData, setOfferMapData] = useState({});

  const userData = getUserData();
  const brandId = userData?.brandProfileData?.id;

  const { state } = useLocation();
  const { priceId, packageOfferId, packageOfferName } = state;

  const [brandPriceList, setBrandPriceList] = useState([]);


  useEffect(() => {
    getBrandPriceCode(brandId)
      .then((response) => {
        if (!response.data.error) {
          const res = response.data;
          const filteredData = res.prices.filter(
            (price) => price.status === true && price.id === priceId
          );
          // const priceList = filteredData.map((item) => ({
          //   label: item.priceCode,
          //   value: item.id,
          // }));
          setBrandPriceList(filteredData);
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });

    let offersMap = {};
    getSinglePackageOffer(brandId, packageOfferId)
      .then((response) => {
        if (!response.data.error) {
          const Res = response.data;
          setSingleOfferData(Res);

          offersMap = Res?.itemList?.reduce((map, item) => {
            // map[item] = item;
            map[item] = true;
            return map;
          }, {});

          setOfferMapData(offersMap);
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });

    const priceListData = {
      priceIds: [priceId],
      priceType: "PRICE",
    };

    // setLoading(true);
    postBrandPriceList(brandId, priceListData)
      .then((response) => {
        if (!response.data.error) {
          setLoading(false);
          // Transforming the data to match the expected structure
          const transformedData = response.data.reduce((acc, item) => {
            const garmentName = item.garmentCategory.garment.name;
            const categoryName = item.garmentCategory.category.name;
            const serviceName = item.appService.name;

            if (!acc[garmentName]) acc[garmentName] = {};
            if (!acc[garmentName][categoryName])
              acc[garmentName][categoryName] = {};

            acc[garmentName][categoryName][serviceName] = {
              // status: item.status,
              id: item.id,
              status:
                offersMap[item.id] !== null && offersMap[item.id] !== undefined
                  ? true
                  : false,
            };

            return acc;
          }, {});

          setGarmentList(transformedData);
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  }, [brandId, priceId]);

  useEffect(() => {
    if (searchQuery) {
      setFilteredGarments(
        Object.keys(garmentList).filter((garment) =>
          garment.toLowerCase().includes(searchQuery.toLowerCase())
        )
      );
    } else {
      setFilteredGarments(Object.keys(garmentList));
    }
  }, [searchQuery, garmentList]);

  const allServices = Array.from(
    new Set(
      Object.keys(garmentList).reduce((services, garment) => {
        Object.keys(garmentList[garment]).forEach((category) => {
          Object.keys(garmentList[garment][category]).forEach((service) => {
            services.push(service);
          });
        });
        return services;
      }, [])
    )
  );

  const handleInputChange = (event, garmentName, categoryName, serviceName) => {
    const value = event.target.checked;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [`${garmentName}-${categoryName}-${serviceName}`]: value,
    }));
  };

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSelectAllChange = (event) => {
    const isChecked = event.target.checked;
    setSelectAllChecked(isChecked);
    const updatedFormData = {};
    Object.keys(garmentList).forEach((garment) => {
      Object.keys(garmentList[garment]).forEach((category) => {
        Object.keys(garmentList[garment][category]).forEach((service) => {
          updatedFormData[`${garment}-${category}-${service}`] = isChecked;
        });
      });
    });
    setFormData(updatedFormData);
  };

  const downloadPDF = (data, allServices, type) => {
    const doc = new jsPDF("landscape");
    const logoImg = new Image();
    // logoImg.src = logoBig;

    // Adjust this as necessary
    // logoImg.src = "/Img/DhobiCart.png";

    // doc.addImage(logoImg, "PNG", 10, 5, 50, 15);
    // Define the check mark Unicode character
    // Define the start points and dimensions
    //  const startX = 10;
    //  const startY = 20;
    //  const checkWidth = 10;
    //  const checkHeight = 10;

    //  // Draw the check mark manually using lines
    //  doc.setLineWidth(1);
    //  doc.line(
    //    startX,
    //    startY + checkHeight / 2,
    //    startX + checkWidth / 2,
    //    startY + checkHeight
    //  );
    //  doc.line(
    //    startX + checkWidth / 2,
    //    startY + checkHeight,
    //    startX + checkWidth * 2,
    //    startY
    //  );

    doc.setFontSize(20);
    doc.text("Offer Garment Management", 110, 25);

    const tableData = data
      .map((garment) => {
        return Object.keys(garmentList[garment])
          .map((category, index) => {
            const row = [garment, category];
            let includeRow = false; // Flag to check if the row should be included
            allServices.forEach((service) => {
              const priceData = garmentList[garment][category][service];
              if (priceData && priceData.status === true) {
                includeRow = true; // Mark the row to be included
                row.push(
                  formData[`${garment}-${category}-${service}`] !== undefined
                    ? formData[`${garment}-${category}-${service}`]
                    : "True"
                );
              } else {
                row.push("");
              }
            });
            return includeRow ? row : null; // Only return the row if it should be included
          })
          .filter((row) => row !== null); // Filter out the null rows
      })
      .flat();


    const tableHeaders = ["Garment Name", "Category", ...allServices];

    doc.autoTable({
      head: [tableHeaders],
      body: tableData,
      startY: 30,
      theme: "grid",
      styles: {
        fontSize: 10,
      },
    });
    if (type === "print") {
      // Create blob URL
      const blob = doc.output("blob");
      const blobURL = URL.createObjectURL(blob);

      // Open blob URL in a new window
      const printWindow = window.open(blobURL);
      if (printWindow) {
        printWindow.onload = () => {
          printWindow.print();
        };
      }
    } else {
      //  doc.save("Offer-garment-management.pdf");
      doc.save("Offer-garment-" + brandPriceList[0].priceCode + ".pdf");
    }
  };

  const handleSubmit = () => {
    setSubmitButtonPressed(true);

    // Initialize submittedData with already present data
    let submittedData = singleOfferData.itemList
      ? [...singleOfferData.itemList]
      : [];

    Object.keys(garmentList).forEach((garment) => {
      Object.keys(garmentList[garment]).forEach((category) => {
        Object.keys(garmentList[garment][category]).forEach((service) => {
          const serviceData = garmentList[garment][category][service];
          const inputValue = formData[`${garment}-${category}-${service}`];
          const originalStatus = serviceData.status;

          if (inputValue !== undefined && !inputValue && serviceData.status) {
            submittedData = submittedData.filter((id) => id !== serviceData.id);
          } else if (serviceData.status) {
            // If serviceData.status is true, add serviceData.id if not already in submittedData
            if (!submittedData.includes(serviceData.id)) {
              submittedData.push(serviceData.id);
            }
          }

          // If inputValue is true, add serviceData.id if not already in submittedData
          if (inputValue && !submittedData.includes(serviceData.id)) {
            submittedData.push(serviceData.id);
          }
        });
      });
    });

    const updatedData = {
      ...singleOfferData,
      itemList: submittedData, // selected checkbox ids
    };

    updatePackageOffer(brandId, updatedData)
      .then((response) => {
        if (!response.data.error) {
          const Res = response.data;
          if (Res) {
            // Reset state variables
            if (response.data.success === false) {
              errorMessage(response.data.message);
              setSubmitButtonPressed(false);
            } else {
              successMessage(response.data.message);
              setSubmitButtonPressed(false);
              navigate("/package-offer-management");
            }
          }
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };

  // const renderGarments = () => {
  //   return filteredGarments.sort().map((garment) =>
  //     Object.keys(garmentList[garment]).map((category, index) => (
  //       <tr key={`${garment}-${category}`}>
  //         <td>{index === 0 ? garment : null}</td>
  //         <td>{category}</td>
  //         {Object.keys(garmentList[garment][category]).map((service) => (
  //           <td key={`${garment}-${category}-${service}`}>
  //             <input
  //               type="checkbox"
  //               style={{ width: 25, height: 25 }}
  //               id={`${garment}-${category}-${service}`}
  //               name={`${garment}-${category}-${service}`}
  //               checked={
  //                 formData[`${garment}-${category}-${service}`] !== undefined
  //                   ? formData[`${garment}-${category}-${service}`]
  //                   : garmentList[garment][category][service].status
  //               }
  //               onChange={(e) =>
  //                 handleInputChange(e, garment, category, service)
  //               }
  //             />
  //           </td>
  //         ))}
  //       </tr>
  //     ))
  //   );
  // };
  const renderGarments = () => {
    return filteredGarments.sort().map((garment) =>
      Object.keys(garmentList[garment]).map((category, index) => (
        <tr key={`${garment}-${category}`}>
          <td>{index === 0 ? garment : null}</td>
          <td>{category}</td>
          {allServices.map((service) => {
            const priceData =
              garmentList[garment] &&
              garmentList[garment][category] &&
              garmentList[garment][category][service];

            // Check if priceData is present and if amount is present
            if (priceData && priceData.status !== undefined) {
              return (
                <td key={`${garment}-${category}-${service}`}>
                  <input
                    type="checkbox"
                    style={{ width: 25, height: 25 }}
                    id={`${garment}-${category}-${service}`}
                    name={`${garment}-${category}-${service}`}
                    checked={
                      formData[`${garment}-${category}-${service}`] !==
                      undefined
                        ? formData[`${garment}-${category}-${service}`]
                        : garmentList[garment][category][service].status
                    }
                    onChange={(e) =>
                      handleInputChange(e, garment, category, service)
                    }
                  />
                </td>
              );
            } else {
              // If amount is not present, render an empty cell
              return <td key={`${garment}-${category}-${service}`}></td>;
            }
          })}
        </tr>
      ))
    );
  };

  return (
    <div style={{ position: "relative", minHeight: "100vh" }}>
      <Navbar
        expand="lg"
        // className=" backgroundColor-white"
        style={{
          backgroundColor: "#FBFBFB",
          //   backgroundImage: "linear-gradient(to bottom, #1752aa, #2b70d7)",
        }}
      >
        <Container>
          {/*<Button
            style={{
              background: "none",
              color: "#1752aa",
              border: "none",
              left: -20,
            }}
            onClick={() => navigate(-1)}
          >
            <i className="pi pi-arrow-left" style={{ fontSize: "2rem" }}></i>
          </Button>*/}
          <Navbar.Brand href="/">
            <img
              style={{ width: "120px" }}
              src="/Img/DhobiCart.png"
              alt="logo"
            />
          </Navbar.Brand>

          <Navbar.Toggle />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            <Nav className="me-auto-new">
              <Nav.Link className="nav-link-home" href="/">
                Home
              </Nav.Link>
              <Nav.Link className="nav-link-design" href="/logout">
                <i
                  className="pi pi-power-off"
                  style={{
                    fontSize: "2rem",
                    color: "red",
                    cursor: "pointer",
                  }}
                ></i>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {loading ? (
        <Row>
          <Col style={{ margin: 10 }}>
            <div style={{ textAlign: "center" }}>
              <h1>Loading...</h1>
            </div>
          </Col>
        </Row>
      ) : Object.keys(garmentList).length > 0 ? (
        <Container fluid>
          <div style={{ textAlign: "center" }}>
            <h3>Package Offer Garment Management</h3>
          </div>
          <div className="row mb-3">
            <div className="col-md-6">
              <div
                style={{
                  // backgroundColor: "#1752AA",
                  // color: "white",
                  marginTop: 20,
                }}
              >
                <div
                  style={{
                    textDecoration: "none",
                    display: "flex",
                    // flex: 1,
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    // width: "280px",
                    // height: "280px",
                    backgroundColor: "#fff",
                    paddingTop: 25,
                    borderRadius: 20,
                    cursor: "pointer",
                    boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                    transition: "0.3s",
                    margin: 5,
                    marginTop: 15,
                    marginBottom: 15,
                  }}
                >
                  <div className="row mb-3">
                    <div className="col-md-12">
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <img
                          style={{ width: "86px" }}
                          src="/Img/rupee_1.png"
                          alt="logo"
                        />
                      </div>
                      <div style={{ textAlign: "center", height: 70 }}>
                        <h5>Tariff List</h5>
                        <p className="service-list display-contents">
                          {brandPriceList[0].priceCode}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div
                style={{
                  // backgroundColor: "#1752AA",
                  // color: "white",
                  marginTop: 20,
                }}
              >
                <div
                  style={{
                    textDecoration: "none",
                    display: "flex",
                    // flex: 1,
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    // width: "280px",
                    // height: "280px",
                    backgroundColor: "#fff",
                    paddingTop: 25,
                    borderRadius: 20,
                    cursor: "pointer",
                    boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                    transition: "0.3s",
                    margin: 5,
                    marginTop: 15,
                    marginBottom: 15,
                  }}
                >
                  <div className="row mb-3">
                    <div className="col-md-12">
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <img
                          style={{ width: "86px", color: "#fff" }}
                          src="/Img/special-tag.png"
                          alt="logo"
                        />
                      </div>
                      <div style={{ textAlign: "center", height: 70 }}>
                        <h5>Offers List</h5>
                        <p className="service-list display-contents">
                          {packageOfferName}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              margin: 20,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <InputText
              value={searchQuery}
              onChange={handleSearchInputChange}
              placeholder="Search Garment"
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div style={{ alignSelf: "center" }}>
                <h5>Select All</h5>
              </div>
              <div style={{ marginLeft: 20, marginRight: 20 }}>
                <input
                  type="checkbox"
                  style={{ width: 30, height: 30 }}
                  checked={selectAllChecked}
                  onChange={handleSelectAllChange}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 20,
                  // marginTop: 10,
                }}
              >
                <i
                  onClick={() =>
                    downloadPDF(
                      filteredGarments.sort(),
                      allServices,
                      "download"
                    )
                  }
                  className="pi pi-download"
                  style={{
                    fontSize: "2rem",
                    color: "#1e439b",
                    cursor: "pointer",
                  }}
                ></i>
                <i
                  onClick={() =>
                    downloadPDF(filteredGarments.sort(), allServices, "print")
                  }
                  className="pi pi-print"
                  style={{
                    fontSize: "2rem",
                    color: "#ff0101",
                    cursor: "pointer",
                  }}
                ></i>
              </div>
            </div>
          </div>
          <Row style={{ marginBottom: 60 }}>
            <Col style={{ margin: 10 }}>
              <main id="main" className="main">
                <section className="section">
                  <div className="row">
                    <div className="col-lg-12">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th>Garment Name</th>
                            <th>Category</th>
                            {allServices.map((service) => (
                              <th key={service}>{service}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>{renderGarments()}</tbody>
                      </table>
                    </div>
                  </div>
                </section>
              </main>
            </Col>
          </Row>
        </Container>
      ) : (
        <Row>
          <Col style={{ margin: 10 }}>
            <div style={{ textAlign: "center" }}>
              <h3 style={{ color: "red" }}>
                Please Add Price in Tariff List Garments
              </h3>
            </div>
          </Col>
        </Row>
      )}
      {Object.keys(garmentList).length > 0 && (
        <div
          style={{
            position: "fixed",
            bottom: 20,
            width: "100%",
            textAlign: "center",
          }}
        >
          <Button
            label="Submit"
            onClick={handleSubmit}
            disabled={submitButtonPressed}
            className="p-button-primary"
            style={{ position: "relative" }}
          />
        </div>
      )}
      <footer>
        <Container fluid>
          <Row>
            <Col>
              <Nav className="flex social-media-div" style={{ marginLeft: 30 }}>
                <Link
                  to="https://www.youtube.com/channel/UCKdUWjGim18SoHC2jLbFsiw"
                  target="_blank"
                >
                  <i
                    className="pi pi-youtube"
                    style={{
                      fontSize: "2rem",
                      color: "red",
                      cursor: "pointer",
                    }}
                  ></i>
                </Link>
                <Link
                  to="https://www.instagram.com/dhobicart/?hl=en"
                  target="_blank"
                >
                  <i
                    className="pi pi-instagram"
                    style={{
                      fontSize: "2rem",
                      color: "#d62976",
                      cursor: "pointer",
                    }}
                  ></i>
                </Link>
                <Link
                  to="https://www.linkedin.com/company/71974228/admin/dashboard/"
                  target="_blank"
                >
                  <i
                    className="pi pi-linkedin"
                    style={{
                      fontSize: "2rem",
                      color: "#0072b1",
                      cursor: "pointer",
                    }}
                  ></i>
                </Link>
                <Link
                  to="https://www.facebook.com/oneindiatechno"
                  target="_blank"
                >
                  <i
                    className="pi pi-facebook"
                    style={{
                      fontSize: "2rem",
                      color: "#1877F2",
                      cursor: "pointer",
                    }}
                  ></i>
                </Link>
                <Link
                  to="https://play.google.com/store/apps/details?id=com.dhobicartV2"
                  target="_blank"
                >
                  <i
                    className="pi pi-android"
                    style={{
                      fontSize: "2rem",
                      color: "#a4c639",
                      cursor: "pointer",
                    }}
                  ></i>
                </Link>
                <Link to="tel:+91 9319518337" target="_blank">
                  <i
                    className="pi pi-phone"
                    style={{
                      fontSize: "2rem",
                      color: "#1877F2",
                      cursor: "pointer",
                    }}
                  ></i>
                </Link>
              </Nav>
            </Col>
            {/*<Col>
              <Link
                to="https://www.oneindiatech.com/?page_id=75"
                target="_blank"
                style={{ textDecoration: "none", fontSize: 20 }}
              >
                Contact Us!
              </Link>
              <p>Phone : +91 9319518337</p>
            </Col>*/}
          </Row>
          <Row>
            <Col>
              <div className="text-center p-3" style={{ fontSize: 16 }}>
                &copy; {new Date().getFullYear()} Copyright:{" "}
                <a className="text-dark" href="https://oneindiatech.com/">
                  One India Technologies Pvt. Ltd.
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </div>
  );
};

export default PackageOffersGarmentSelection;
