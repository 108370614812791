import React, { useEffect, useState } from "react";

const TrackOrder = () => {
  const [orderInfo, setOrderInfo] = useState([]);

  const queryParameters = new URLSearchParams(window.location.search);
  const type = queryParameters.get("type");
  const name = queryParameters.get("name");

  useEffect(() => {
    // const storeCustomerId = 2;
    // const storeId = 1;
    // const bookingOrderId = 1606;

    //   BOOKED; 150824121153NMHNo5Iz //
    //   INPROCESS; 160824152439bsn7Pu22 //
    //   PROCESSED; 160824174200P5MEqxC9 //
    //   DELIVERED; 160824152550tf6jE71Z
    //   DONE; 160824170659Xi6Jd5kh
    //   CANCEL;  140824161814DcEuOs3r //
    //   UNPROCESSED; 2008241742378AJ6MVhC //
    //   OUT_FOR_DELIVERY; 100824144146FaJNHD2x //
    //   IN_WAREHOUSE; 130824153231FLLaxFM3 //

    fetch("https://dhobicart.in/store/order-status/100824144146FaJNHD2x", {
      method: "get",
      headers: {
        Authorization:
          "Bearer eyJhbGciOiJIUzUxMiJ9.eyJhdXRob3JpdGllcyI6WyJST0xFX0FETUlOIl0sImRldmljZVRva2VuIjoiZDQxZDhjZDk4ZjAwYjIwNGU5ODAwOTk4ZWNmODQyN2UiLCJsb2dnZWRJblVzZXJJZCI6IkRDT0lUR0VEIiwibG9nZ2VkSW5Vc2VyQnJhbmRJZCI6IjEiLCJzb3VyY2UiOiJ3ZWIiLCJzdWIiOiIxMCIsImlhdCI6MTcyNDEzNjk3OSwiZXhwIjoxNzI0NzQxNzc5fQ.huewgwlIAXo_6Bj_OBipmLrN-i0KJMS4JtM4Jmf4qVKQ-iVJVcv-Nw630HuPuPvZdHqRcPvsA9Ns4ppKzfDpYg",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setOrderInfo(data);
      });

      const handleResize = () => setWidth(window.innerWidth);
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [width, setWidth] = useState(window.innerWidth);


  const trackingNo = orderInfo.invoiceNo;
  const bookingDate = orderInfo.createdDate;
  const customerName = orderInfo.customerName;
  const customerMobile = orderInfo.customerMobile;
  const customerAddress = orderInfo.customerAddress;
  const lastModifiedDate = orderInfo.lastModifiedDate;
  const orderNo = orderInfo.orderNo;
  const status = orderInfo.status;
  //   const status = "BOOKED";
  const deliveredOn = orderInfo.deliveredOn;
  const deliveryOn = orderInfo.deliveryOn;

  const bookedBy = orderInfo.bookedBy;
  const storeName = orderInfo.store?.storeName;
  const storeMobileNo = orderInfo.store?.mobileNo;
  const storeAddress = orderInfo.store?.address.fullAddress;

  let statusBookedDone = false;
  let statusInProcessDone = false;
  let statusProcessDone = false;
  let statusInWarehouseDone = false;
  let statusOutForDeliveryDone = false;
  let statusDeliveredDone = false;
  let statusUnProcessDone = false;
  let statusCancel = false;
  let statusRunning = false;
  let statusBookedRunning = false;
  let statusInProcessRunning = false;
  let statusProcessRunning = false;

  if (status === "BOOKED") {
    statusBookedDone = true;
  } else if (status === "INPROCESS") {
    statusBookedDone = true;
    statusInProcessDone = true;
    statusBookedRunning = true;
  } else if (status === "PROCESSED") {
    statusBookedDone = true;
    statusInProcessDone = true;
    statusProcessDone = true;
    statusBookedRunning = true;
    statusInProcessRunning = true;
  } else if (status === "IN_WAREHOUSE") {
    statusBookedDone = true;
    statusInProcessDone = true;
    statusInWarehouseDone = true;
    statusBookedRunning = true;
    statusInProcessRunning = true;
    statusProcessRunning = true;
  } else if (status === "OUT_FOR_DELIVERY") {
    statusBookedDone = true;
    statusInProcessDone = true;
    statusProcessDone = true;
    statusOutForDeliveryDone = true;
    statusBookedRunning = true;
    statusInProcessRunning = true;
    statusProcessRunning = true;
  } else if (status === "UNPROCESSED") {
    statusBookedDone = true;
    statusUnProcessDone = true;
    statusBookedRunning = true;
  } else if (status === "DELIVERED" || status === "DONE") {
    statusBookedDone = true;
    statusDeliveredDone = true;
    statusInProcessRunning = true;
    statusProcessRunning = true;
    statusBookedRunning = true;
  } else {
    statusBookedDone = true;
    statusCancel = true;
    statusBookedRunning = true;
    statusInProcessRunning = true;
    statusProcessRunning = true;
  }

  const formatDate = (dateString) => {
    const parsedDate = new Date(dateString?.replace("Z", ""));

    return parsedDate.toLocaleString("en-IN", {
      month: "short",
      day: "2-digit",
      year: "numeric",
      hour12: true,
    });
  };

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <div
        style={{
          flex: 0.3,
          margin: width < 500 ? "15px" : "30px",
          background: "#fff",
        }}
      >
        <div style={{ marginTop: width < 500 ? "2px" : "30px" }}>
          <div>
            <span
              style={{ fontSize: width < 500 ? 12 : 14, fontWeight: "700" }}
            >
              Bill No.
            </span>
          </div>
          <span
            style={{
              textAlign: "left",
              color: "#737270",
              fontSize: 18,

              fontWeight: "700",
              marginBottom: "0px !important",
            }}
          >
            {trackingNo}
          </span>
        </div>
        <div
          style={{
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: "#e3e1dc",
            marginTop: width < 500 ? "5px" : "15px",
            marginBottom: width < 500 ? "5px" : "15px",
          }}
        ></div>
        <div style={{ marginBottom: width < 500 ? "5px" : "15px" }}>
          <div
            style={{
              marginTop: width < 500 ? "5px" : "15px",
              marginBottom: width < 500 ? "5px" : "15px",
            }}
          >
            <div>
              <span
                style={{ fontSize: width < 500 ? 12 : 14, fontWeight: "700" }}
              >
                Customer Name
              </span>
            </div>
            <span
              style={{
                textAlign: "left",
                color: "#737270",
                fontSize: width < 500 ? 13 : 18,

                fontWeight: "700",
              }}
            >
              {customerName}
            </span>
          </div>
          <div
            style={{
              marginTop: width < 500 ? "5px" : "15px",
              marginBottom: width < 500 ? "5px" : "15px",
            }}
          >
            <div>
              <span
                style={{ fontSize: width < 500 ? 12 : 14, fontWeight: "700" }}
              >
                Customer Mobile
              </span>
            </div>
            <span
              style={{
                textAlign: "left",
                color: "#737270",
                fontSize: width < 500 ? 13 : 18,

                fontWeight: "700",
              }}
            >
              {customerMobile}
            </span>
          </div>
          <div style={{ marginTop: width < 500 ? "5px" : "15px" }}>
            <div>
              <span
                style={{ fontSize: width < 500 ? 12 : 14, fontWeight: "700" }}
              >
                Address
              </span>
            </div>
            <span
              style={{
                textAlign: "left",
                color: "#737270",
                fontSize: width < 500 ? 13 : 18,

                fontWeight: "700",
              }}
            >
              {customerAddress}
            </span>
          </div>
        </div>
        <div
          style={{
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: "#e3e1dc",
            marginTop: width < 500 ? "5px" : "15px",
            marginBottom: width < 500 ? "5px" : "15px",
          }}
        ></div>
        <div style={{ marginBottom: width < 500 ? "10px" : "30px" }}>
          <div
            style={{
              marginTop: width < 500 ? "5px" : "15px",
              marginBottom: width < 500 ? "5px" : "15px",
            }}
          >
            <div>
              <span
                style={{ fontSize: width < 500 ? 12 : 14, fontWeight: "700" }}
              >
                Booked By
              </span>
            </div>
            <span
              style={{
                textAlign: "left",
                color: "#737270",
                fontSize: width < 500 ? 13 : 18,

                fontWeight: "700",
              }}
            >
              {bookedBy}
            </span>
          </div>
          <div
            style={{
              marginTop: width < 500 ? "5px" : "15px",
              marginBottom: width < 500 ? "5px" : "15px",
            }}
          >
            <div>
              <span
                style={{ fontSize: width < 500 ? 12 : 14, fontWeight: "700" }}
              >
                Store Name
              </span>
            </div>
            <span
              style={{
                textAlign: "left",
                color: "#737270",
                fontSize: width < 500 ? 13 : 18,

                fontWeight: "700",
              }}
            >
              {storeName}
            </span>
          </div>
          <div
            style={{
              marginTop: width < 500 ? "5px" : "15px",
              marginBottom: width < 500 ? "5px" : "15px",
            }}
          >
            <div>
              <span
                style={{ fontSize: width < 500 ? 12 : 14, fontWeight: "700" }}
              >
                Store Mobile
              </span>
            </div>
            <span
              style={{
                textAlign: "left",
                color: "#737270",
                fontSize: width < 500 ? 13 : 18,

                fontWeight: "700",
              }}
            >
              {storeMobileNo}
            </span>
          </div>
          <div
            style={{
              marginTop: width < 500 ? "5px" : "15px",
              marginBottom: width < 500 ? "5px" : "15px",
            }}
          >
            <div>
              <span
                style={{ fontSize: width < 500 ? 12 : 14, fontWeight: "700" }}
              >
                Store Email
              </span>
            </div>
            <span
              style={{
                textAlign: "left",
                color: "#737270",
                fontSize: width < 500 ? 13 : 18,

                fontWeight: "700",
              }}
            >
              {storeAddress}
            </span>
          </div>
        </div>
      </div>
      <div
        style={{
          flex: 0.7,
          display: "flex",
          flexDirection: "row",
          background: "#fff",
        }}
      >
        <div
          style={{
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: "#e3e1dc",
            marginTop: width < 500 ? "5px" : "15px",
            marginBottom: width < 500 ? "5px" : "15px",
          }}
        ></div>
        <div
          style={{
            flex: 1,
            margin: width < 500 ? "10px" : "30px",
            background: "#fff",
          }}
        >
          <div style={{ marginTop: width < 500 ? "5px" : "15px" }}>
            <span style={{ fontSize: width < 500 ? 18 : 22, fontWeight: "700" }}>
              Order Info
            </span>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: width < 500 ? "column" : "row",
              justifyContent: width < 500 ? "flex-start" : "space-between",
            }}
          >
            <div
              style={{
                marginTop: width < 500 ? "5px" : "15px",
                marginBottom: width < 500 ? "0px" : "15px",
              }}
            >
              <div>
                <span
                  style={{ fontSize: width < 500 ? 12 : 14, fontWeight: "700" }}
                >
                  Order No.
                </span>
              </div>
              <span
                style={{
                  textAlign: "left",
                  color: "#737270",
                  fontSize: width < 500 ? 13 : 18,

                  fontWeight: "700",
                }}
              >
                {orderNo}
              </span>
            </div>
            <div
              style={{
                marginTop: width < 500 ? "0px" : "15px",
                marginBottom: width < 500 ? "5px" : "15px",
              }}
            >
              <div>
                <span
                  style={{ fontSize: width < 500 ? 12 : 14, fontWeight: "700" }}
                >
                  Date
                </span>
              </div>
              <span
                style={{
                  textAlign: "left",
                  color: "#737270",
                  fontSize: width < 500 ? 13 : 18,

                  fontWeight: "700",
                }}
              >
                {formatDate(bookingDate)}
              </span>
            </div>
          </div>
          <div
            style={{
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "#e3e1dc",
              marginBottom: width < 500 ? "5px" : "15px",
            }}
          ></div>
          <div style={{ marginTop: width < 500 ? "5px" : "15px" }}>
            <span
              style={{
                textAlign: "left",
                color: "#000",
                fontSize: width < 500 ? 13 : 16,
                fontWeight: "700",
              }}
            >
              Your Order is
            </span>
            <div>
              <span
                style={{
                  marginTop: width < 500 ? "5px" : "15px",
                  marginBottom: width < 500 ? "5px" : "15px",
                  fontSize: width < 500 ? 20 : 40,
                  fontWeight: "700",
                }}
              >
                {status?.replace(/_/g, " ")}
              </span>
            </div>
            <span
              style={{
                textAlign: "left",
                color: "#000",
                fontSize: width < 500 ? 13 : 16,
                fontWeight: "700",
              }}
            >
              as on {formatDate(lastModifiedDate)}
            </span>
          </div>
          <div
            style={{
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "#e3e1dc",
              marginTop: width < 500 ? "5px" : "15px",
              marginBottom: width < 500 ? "5px" : "15px",
            }}
          ></div>
          <div>
            <div style={{ marginTop: width < 500 ? "5px" : "15px" }}>
              <span
                style={{ fontSize: width < 500 ? 14 : 18, fontWeight: "700" }}
              >
                Tracking Order Status
              </span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: width < 500 ? "5px" : "20px",
                marginBottom: width < 500 ? "5px" : "20px",
              }}
            >
              <div style={{ marginRight: width < 500 ? "10px" : "20px" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      borderWidth: "10px",
                      //   height: "10px",
                      borderStyle: "solid",
                      borderColor: statusBookedDone ? "#1cd955" : "#cfcccc",
                      borderRadius: "30px",
                      position: "absolute",
                      top: "30px",
                    }}
                  ></div>
                  <div
                    style={{
                      borderWidth: "2px",
                      borderStyle: "solid",
                      borderColor: statusBookedRunning ? "#1cd955" : "#cfcccc",
                      left: "8px",
                      top: "50px",
                      position: "absolute",
                      height: width < 500 ? "100px" : "80px",
                    }}
                  ></div>
                </div>
              </div>
              <div style={{ marginLeft: "10px", marginRight: "10px" }}>
                <img
                  src="https://cdn-icons-png.flaticon.com/512/869/869443.png"
                  alt="recipt"
                  style={{ width: "70px" }}
                />
              </div>
              <div
                style={{
                  marginLeft: width < 500 ? "2px" : "10px",
                  marginRight: width < 500 ? "2px" : "10px",
                }}
              >
                <span
                  style={{
                    textAlign: "left",
                    color: "#575656",
                    fontSize: width < 500 ? 13 : 16,
                    fontWeight: "700",
                  }}
                >
                  Booked
                </span>
                <div>
                  <span
                    style={{
                      textAlign: "left",
                      color: "#6b6a6a",
                      fontSize: width < 500 ? 12 : 14,
                      fontWeight: "500",
                    }}
                  >
                    we have receive you order on {formatDate(bookingDate)}
                  </span>
                </div>
              </div>
            </div>
            {statusInProcessDone && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
              >
                <div style={{ marginRight: width < 500 ? "10px" : "20px" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        borderWidth: "10px",
                        // height: "10px",
                        borderStyle: "solid",
                        borderColor: statusInProcessDone
                          ? "#1cd955"
                          : "#cfcccc",
                        borderRadius: "30px",
                        position: "absolute",
                        top: "30px",
                      }}
                    ></div>
                    <div
                      style={{
                        borderWidth: "2px",
                        borderStyle: "solid",
                        borderColor: statusInProcessRunning
                          ? "#1cd955"
                          : "#cfcccc",
                        left: "8px",
                        top: "50px",
                        position: "absolute",
                        height: width < 500 ? "100px" : "80px",
                      }}
                    ></div>
                  </div>
                </div>
                <div style={{ marginLeft: "10px", marginRight: "10px" }}>
                  <img
                    src="https://cdn-icons-png.flaticon.com/512/869/869443.png"
                    alt="recipt"
                    style={{ width: "70px" }}
                  />
                </div>
                <div
                  style={{
                    marginLeft: width < 500 ? "2px" : "10px",
                    marginRight: width < 500 ? "2px" : "10px",
                  }}
                >
                  <span
                    style={{
                      textAlign: "left",
                      color: "#575656",
                      fontSize: width < 500 ? 13 : 16,
                      fontWeight: "700",
                    }}
                  >
                    InProcess
                  </span>
                  <div>
                    <span
                      style={{
                        textAlign: "left",
                        color: "#6b6a6a",
                        fontSize: width < 500 ? 12 : 14,
                        fontWeight: "500",
                      }}
                    >
                      Order is InProcess
                    </span>
                  </div>
                </div>
              </div>
            )}
            {statusProcessDone && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
              >
                <div style={{ marginRight: width < 500 ? "10px" : "20px" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        borderWidth: "10px",
                        // height: "10px",
                        borderStyle: "solid",
                        borderColor: statusProcessDone ? "#1cd955" : "#cfcccc",
                        borderRadius: "30px",
                        position: "absolute",
                        top: "30px",
                      }}
                    ></div>
                    <div
                      style={{
                        borderWidth: "2px",
                        borderStyle: "solid",
                        borderColor: statusProcessRunning
                          ? "#1cd955"
                          : "#cfcccc",
                        left: "8px",
                        top: "50px",
                        position: "absolute",
                        height: width < 500 ? "100px" : "80px",
                      }}
                    ></div>
                  </div>
                </div>
                <div style={{ marginLeft: "10px", marginRight: "10px" }}>
                  <img
                    src="https://cdn-icons-png.flaticon.com/512/869/869443.png"
                    alt="recipt"
                    style={{ width: "70px" }}
                  />
                </div>
                <div
                  style={{
                    marginLeft: width < 500 ? "2px" : "10px",
                    marginRight: width < 500 ? "2px" : "10px",
                  }}
                >
                  <span
                    style={{
                      textAlign: "left",
                      color: "#575656",
                      fontSize: width < 500 ? 13 : 16,
                      fontWeight: "700",
                    }}
                  >
                    Processed
                  </span>
                  <div>
                    <span
                      style={{
                        textAlign: "left",
                        color: "#6b6a6a",
                        fontSize: width < 500 ? 12 : 14,
                        fontWeight: "500",
                      }}
                    >
                      Order is Processed
                    </span>
                  </div>
                </div>
              </div>
            )}
            {statusInWarehouseDone && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
              >
                <div style={{ marginRight: width < 500 ? "10px" : "20px" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        borderWidth: "10px",
                        // height: "10px",
                        borderStyle: "solid",
                        borderColor: statusInWarehouseDone
                          ? "#1cd955"
                          : "#cfcccc",
                        borderRadius: "30px",
                        position: "absolute",
                        top: "30px",
                      }}
                    ></div>
                    <div
                      style={{
                        borderWidth: "2px",
                        borderStyle: "solid",
                        borderColor: statusRunning ? "#1cd955" : "#cfcccc",
                        left: "8px",
                        top: "50px",
                        position: "absolute",
                        height: width < 500 ? "100px" : "80px",
                      }}
                    ></div>
                  </div>
                </div>
                <div style={{ marginLeft: "10px", marginRight: "10px" }}>
                  <img
                    src="https://cdn-icons-png.flaticon.com/512/869/869443.png"
                    alt="recipt"
                    style={{ width: "70px" }}
                  />
                </div>
                <div
                  style={{
                    marginLeft: width < 500 ? "2px" : "10px",
                    marginRight: width < 500 ? "2px" : "10px",
                  }}
                >
                  <span
                    style={{
                      textAlign: "left",
                      color: "#575656",
                      fontSize: width < 500 ? 13 : 16,
                      fontWeight: "700",
                    }}
                  >
                    InWarehouse
                  </span>
                  <div>
                    <span
                      style={{
                        textAlign: "left",
                        color: "#6b6a6a",
                        fontSize: width < 500 ? 12 : 14,
                        fontWeight: "500",
                      }}
                    >
                      Order is InWarehouse
                    </span>
                  </div>
                </div>
              </div>
            )}
            {statusOutForDeliveryDone && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
              >
                <div style={{ marginRight: width < 500 ? "10px" : "20px" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        borderWidth: "10px",
                        // height: "10px",
                        borderStyle: "solid",
                        borderColor: statusOutForDeliveryDone
                          ? "#1cd955"
                          : "#cfcccc",
                        borderRadius: "30px",
                        position: "absolute",
                        top: "30px",
                      }}
                    ></div>
                    <div
                      style={{
                        borderWidth: "2px",
                        borderStyle: "solid",
                        borderColor: statusRunning ? "#1cd955" : "#cfcccc",
                        left: "8px",
                        top: "50px",
                        position: "absolute",
                        height: width < 500 ? "90px" : "80px",
                      }}
                    ></div>
                  </div>
                </div>
                <div style={{ marginLeft: "10px", marginRight: "10px" }}>
                  <img
                    src="https://cdn-icons-png.flaticon.com/512/869/869443.png"
                    alt="recipt"
                    style={{ width: "70px" }}
                  />
                </div>
                <div
                  style={{
                    marginLeft: width < 500 ? "2px" : "10px",
                    marginRight: width < 500 ? "2px" : "10px",
                  }}
                >
                  <span
                    style={{
                      textAlign: "left",
                      color: "#575656",
                      fontSize: width < 500 ? 13 : 16,
                      fontWeight: "700",
                    }}
                  >
                    Out For Delivery
                  </span>
                  <div>
                    <span
                      style={{
                        textAlign: "left",
                        color: "#6b6a6a",
                        fontSize: width < 500 ? 12 : 14,
                        fontWeight: "500",
                      }}
                    >
                      Your Order is Out For Delivery
                    </span>
                  </div>
                </div>
              </div>
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              <div style={{ marginRight: width < 500 ? "10px" : "20px" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      borderWidth: "10px",
                      //   height: "10px",
                      borderStyle: "solid",
                      borderColor:
                        statusUnProcessDone || statusCancel
                          ? "red"
                          : statusDeliveredDone
                          ? "#1cd955"
                          : "#cfcccc",
                      borderRadius: "30px",
                      position: "absolute",
                      top: "30px",
                    }}
                  ></div>
                </div>
              </div>
              <div style={{ marginLeft: "10px", marginRight: "10px" }}>
                <img
                  src="https://cdn-icons-png.flaticon.com/512/869/869443.png"
                  alt="recipt"
                  style={{ width: "70px" }}
                />
              </div>
              <div
                style={{
                  marginLeft: width < 500 ? "2px" : "10px",
                  marginRight: width < 500 ? "2px" : "10px",
                }}
              >
                <span
                  style={{
                    textAlign: "left",
                    color: "#575656",
                    fontSize: width < 500 ? 13 : 16,
                    fontWeight: "700",
                  }}
                >
                  {status === "CANCEL"
                    ? "CANCEL"
                    : status === "UNPROCESSED"
                    ? "UNPROCESSED"
                    : deliveredOn !== null
                    ? "Delivered"
                    : "Delivery On"}
                </span>
                <div>
                  <span
                    style={{
                      textAlign: "left",
                      color: "#6b6a6a",
                      fontSize: width < 500 ? 12 : 14,
                      fontWeight: "500",
                    }}
                  >
                    {status === "CANCEL"
                      ? "Your Order is Cancel On " +
                        formatDate(lastModifiedDate)
                      : status === "UNPROCESSED"
                      ? "Your Order is UnProcessed"
                      : deliveredOn !== null 
                      ? "Your Order is Delivered On " + formatDate(deliveredOn)
                      : "Your Order will be Delivery On " +
                        formatDate(deliveryOn)}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrackOrder;
