import React, { useEffect, useState } from "react";
import { getUserData } from "../../utils/localStorage";
import { Card, Col, Container, Nav, Navbar, Row } from "react-bootstrap";
import { Dropdown } from "primereact/dropdown";
import { errorMessage, successMessage } from "../../utils/alertMessage";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { getBrandAppService } from "../../services/BrandAppServices";
import { getBrandPriceCode } from "../../services/getPriceCode";
import { getBrandOffer } from "../../services/getBrandOffers";

const OfferManagement = () => {
  const navigate = useNavigate();

  const userData = getUserData();
  let brandId = userData.brandProfileData.id;
  // const offerList = ["one", "two", "three"];

  const [brandPriceList, setBrandPriceList] = useState([]);
  const [offerList, setOfferList] = useState([]);
  const [filteredOfferList, setFilteredOfferList] = useState([]);
  const [submitButtonPressed, setSubmitButtonPressed] = useState(false);

  const [input, setInput] = useState({
    offers: "",
  });
  const [errors, setErrors] = useState({
    priceId:"",
    offers: "",
  });

  // const fetchGetBrandPriceCode = () => {
  //   getBrandPriceCode(brandId)
  //     .then((response) => {
  //       if (!response.data.error) {
  //         const res = response.data;
  //         // const priceList = res.prices;
  //         const filteredData = res.prices.filter(
  //           (price) => price.status === true
  //         );
  //         const priceList = filteredData.map((item) => ({
  //           label: item.priceCode,
  //           value: item.id,
  //         }));
  //         setBrandPriceList(priceList);
  //       }
  //     })
  //     .catch((err) => {
  //       console.error("Error:", err);
  //     });

  //   getBrandOffer(brandId)
  //     .then((response) => {
  //       if (!response.data.error) {
  //         const res = response.data;
  //         // const priceList = res.prices;
  //         // const filteredData = res.prices.filter(
  //         //   (price) => price.status === true
  //         // );
  //         // const priceList = filteredData.map((item) => ({
  //         //   label: item.priceCode,
  //         //   value: item.id,
  //         // }));
  //         // setBrandPriceList(priceList);
  //       }
  //     })
  //     .catch((err) => {
  //       console.error("Error:", err);
  //     });
  // };

  const fetchGetBrandPriceCode = () => {
    getBrandPriceCode(brandId)
      .then((response) => {
        if (!response.data.error) {
          const res = response.data;
          const filteredData = res.prices.filter(
            (price) => price.status === true
          );
          const priceList = filteredData.map((item) => ({
            label: item.priceCode,
            value: item.id,
          }));
          setBrandPriceList(priceList);
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });

    getBrandOffer(brandId)
      .then((response) => {
        if (!response.data.error) {
          const res = response.data;
          
          const offers = res.map((item) => ({
            ...item,
            label: item.name,
            value: item,
            priceId: item.priceId,
          }));
          setOfferList(offers);
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };

  useEffect(() => {
    fetchGetBrandPriceCode();
  }, []);

  const validateForm = () => {
    let isValid = true;
    const newErrors = { ...errors };

    if (input.priceId === "") {
      newErrors.priceId = "Please select any Tariff List";
      isValid = false;
    } else {
      newErrors.priceId = "";
    }
    if (input.offers === "") {
      newErrors.offers = "Please select any Offer";
      isValid = false;
    } else {
      newErrors.offers = "";
    }
    setErrors(newErrors);
    return isValid;
  };

  const handlePriceChange = (e) => {
    const selectedPriceId = e.target.value;
    setInput({ ...input, priceId: selectedPriceId });
    const filteredOffers = offerList.filter(
      (offer) => offer.priceId === selectedPriceId
    );
    setFilteredOfferList(filteredOffers);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitButtonPressed(true);
    if (validateForm()) {
      let data = {
        offers: input.offers,
        priceId: input.priceId,
      };

      navigate("/offer-garment-selection", {
        state: {
          offerId: input.offers.id,
          offerName: input.offers.name,
          priceId: input.priceId,
        },
      });

    } else {
      setSubmitButtonPressed(false);
    }
  };

  return (
    <div>
      <Navbar
        expand="lg"
        // className=" backgroundColor-white"
        style={{
          backgroundColor: "#FBFBFB",
          //   backgroundImage: "linear-gradient(to bottom, #1752aa, #2b70d7)",
        }}
      >
        <Container>
          {/*<Button
            style={{
              background: "none",
              color: "#1752aa",
              border: "none",
              left: -20,
            }}
            onClick={() => navigate(-1)}
          >
            <i className="pi pi-arrow-left" style={{ fontSize: "2rem" }}></i>
          </Button>*/}
          <Navbar.Brand href="/">
            <img
              style={{ width: "120px" }}
              src="/Img/DhobiCart.png"
              alt="logo"
            />
          </Navbar.Brand>

          <Navbar.Toggle />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            <Nav className="me-auto-new">
              <Nav.Link className="nav-link-home" href="/">
                Home
              </Nav.Link>
              <Nav.Link className="nav-link-design" href="/logout">
                <i
                  className="pi pi-power-off"
                  style={{
                    fontSize: "2rem",
                    color: "red",
                    cursor: "pointer",
                  }}
                ></i>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container fluid>
        <div style={{ textAlign: "center", marginBottom: 30 }}>
          <h3>Offer Garment Management</h3>
        </div>
        <form onSubmit={handleSubmit}>
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "row",
              // backgroundColor: "red",
            }}
          >
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                // marginLeft: 25,
                // backgroundColor: "green",
              }}
            >
              <div
                style={{
                  textDecoration: "none",
                  display: "flex",
                  // flex: 1,
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "450px",
                  // height: "280px",
                  backgroundColor: "#fff",
                  padding: 25,
                  borderRadius: 20,
                  cursor: "pointer",
                  boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                  transition: "0.3s",
                  margin: 5,
                  marginTop: 15,
                  marginBottom: 15,
                }}
              >
                <h4
                  style={{
                    textAlign: "center",
                    color: "#1752AA",
                    margin: 20,
                  }}
                >
                  Tariff List
                </h4>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    style={{ width: "126px", marginBottom: 10 }}
                    src="/Img/rupee_1.png"
                    alt="logo"
                  />
                </div>
                <Dropdown
                  value={input.priceId}
                  style={{ width: "100%" }}
                  options={brandPriceList}
                  onChange={handlePriceChange}
                  placeholder="Select a Tariff List"
                />
                <div className="text-danger mt-1">{errors.priceId}</div>
              </div>
            </div>
            <div
              style={{
                flex: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                // marginRight: 25,
              }}
            >
              <div
                style={{
                  textDecoration: "none",
                  display: "flex",
                  // flex: 1,
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "450px",
                  height: "298px",
                  backgroundColor: "#fff",
                  padding: 25,
                  borderRadius: 20,
                  cursor: "pointer",
                  boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                  transition: "0.3s",
                  margin: 5,
                  marginTop: 15,
                  marginBottom: 15,
                }}
              >
                <h4
                  style={{
                    textAlign: "center",
                    color: "#1752AA",
                    margin: 20,
                  }}
                >
                  Offers List
                </h4>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    style={{ width: "126px", marginBottom: 10 }}
                    src="/Img/special-tag.png"
                    alt="logo"
                  />
                </div>
                {input.priceId && (
                  <Dropdown
                    value={input.offers}
                    options={filteredOfferList}
                    style={{ width: "100%" }}
                    onChange={(e) => {
                      setInput({
                        ...input,
                        offers: e.target.value,
                      });
                    }}
                    placeholder="Select a Offer"
                  />
                )}
                <div className="text-danger mt-1">{errors.offers}</div>
              </div>
            </div>
          </div>
          <div className="col-md-12 text-center mt-4">
            <button
              type="submit"
              className="move-btn-a move-btn-su"
              disabled={submitButtonPressed}
            >
              Submit
            </button>
          </div>
        </form>
        {/*<Row>
          <Col style={{ margin: 10 }}>
            <div style={{ textAlign: "center" }}>
              <h3>Offer Management</h3>
            </div>
            <main id="main" className="main">
              <section className="section">
                <div className="row">
                  <div className="col-lg-12">
                    <Card
                      style={{
                        backgroundColor: "#1752AA",
                        color: "white",
                        marginTop: 20,
                      }}
                    >
                      <Card.Body>
                        <form onSubmit={handleSubmit}>
                          <div className="row mb-3">
                            <div className="col-md-12">
                              <label htmlFor="priceId" className="form-label">
                                Tariff List
                              </label>
                            </div>
                            <Dropdown
                              value={input.priceId}
                              options={brandPriceList}
                              // onChange={(e) => {
                              //   setInput({
                              //     ...input,
                              //     priceId: e.target.value,
                              //   });
                              // }}
                              onChange={handlePriceChange}
                              placeholder="Select a Tariff List"
                            />
                            <div className="text-danger mt-1">
                              {errors.priceId}
                            </div>
                          </div>
                          <div className="row mb-3">
                            <div className="col-md-12">
                              <label htmlFor="offers" className="form-label">
                                Offers List
                              </label>
                            </div>
                            <Dropdown
                              value={input.offers}
                              options={filteredOfferList}
                              onChange={(e) => {
                                setInput({
                                  ...input,
                                  offers: e.target.value,
                                });
                              }}
                              placeholder="Select a Offer"
                            />
                            <div className="text-danger mt-1">
                              {errors.offers}
                            </div>
                          </div>
                          <div className="col-md-12 text-center">
                            <button
                              type="submit"
                              className="move-btn-a move-btn-mu"
                              disabled={submitButtonPressed}
                            >
                              Submit
                            </button>
                          </div>
                        </form>
                      </Card.Body>
                    </Card>
                  </div>
                </div>
              </section>
            </main>
          </Col>
          <Col style={{ textAlign: "center", marginTop: 50 }}>
            <img src="/Img/5.png" alt="logo" />
          </Col>
        </Row>*/}
      </Container>
      <footer style={{ backgroundColor: "#FBFBFB" }}>
        <Container fluid>
          <Row>
            <Col>
              <Nav className="flex social-media-div" style={{ marginLeft: 30 }}>
                <Link
                  to="https://www.youtube.com/channel/UCKdUWjGim18SoHC2jLbFsiw"
                  target="_blank"
                >
                  <i
                    className="pi pi-youtube"
                    style={{
                      fontSize: "2rem",
                      color: "red",
                      cursor: "pointer",
                    }}
                  ></i>
                </Link>
                <Link
                  to="https://www.instagram.com/dhobicart/?hl=en"
                  target="_blank"
                >
                  <i
                    className="pi pi-instagram"
                    style={{
                      fontSize: "2rem",
                      color: "#d62976",
                      cursor: "pointer",
                    }}
                  ></i>
                </Link>
                <Link
                  to="https://www.linkedin.com/company/71974228/admin/dashboard/"
                  target="_blank"
                >
                  <i
                    className="pi pi-linkedin"
                    style={{
                      fontSize: "2rem",
                      color: "#0072b1",
                      cursor: "pointer",
                    }}
                  ></i>
                </Link>
                <Link
                  to="https://www.facebook.com/oneindiatechno"
                  target="_blank"
                >
                  <i
                    className="pi pi-facebook"
                    style={{
                      fontSize: "2rem",
                      color: "#1877F2",
                      cursor: "pointer",
                    }}
                  ></i>
                </Link>
                <Link
                  to="https://play.google.com/store/apps/details?id=com.dhobicartV2"
                  target="_blank"
                >
                  <i
                    className="pi pi-android"
                    style={{
                      fontSize: "2rem",
                      color: "#a4c639",
                      cursor: "pointer",
                    }}
                  ></i>
                </Link>
                <Link to="tel:+91 9319518337" target="_blank">
                  <i
                    className="pi pi-phone"
                    style={{
                      fontSize: "2rem",
                      color: "#1877F2",
                      cursor: "pointer",
                    }}
                  ></i>
                </Link>
              </Nav>
            </Col>
            {/*<Col>
              <Link
                to="https://www.oneindiatech.com/?page_id=75"
                target="_blank"
                style={{ textDecoration: "none", fontSize: 20 }}
              >
                Contact Us!
              </Link>
              <p>Phone : +91 9319518337</p>
            </Col>*/}
          </Row>
          <Row>
            <Col>
              <div className="text-center p-3" style={{ fontSize: 16 }}>
                &copy; {new Date().getFullYear()} Copyright:{" "}
                <a className="text-dark" href="https://oneindiatech.com/">
                  One India Technologies Pvt. Ltd.
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </div>
  );
};

export default OfferManagement;
